import { useAuth0 } from '@auth0/auth0-react'
import { DefaultButton, Text } from '@fluentui/react'
import { Card, CardBody } from './Card'

function Login ({ error, isLogin }) {
  const { loginWithRedirect, logout } = useAuth0()
  return (
    <Card>
      <CardBody>
        <Text variant='large'>{error.message}</Text>
      </CardBody>
      <CardBody>
        {isLogin && <DefaultButton onClick={loginWithRedirect} text='登录' />}
        {!isLogin && <DefaultButton
          onClick={() => logout({
            returnTo: window.location.origin
          })} text='退出'
                     />}
      </CardBody>
    </Card>
  )
}

export default Login
