import { useEffect, createContext, useRef, useContext } from 'react'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import * as Actions from './Actions'
import { SIGNALR_ENDPOINT } from './CONST'

function buildSignalrConnection (token) {
  return new HubConnectionBuilder()
    .withUrl(SIGNALR_ENDPOINT, { accessTokenFactory: () => token })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build()
}

function notifier (type, dispatch) {
  return message => {
    dispatch({
      type,
      data: {
        ...message,
        date: new Date().toLocaleString()
      }
    })
  }
}

export const SignalrContext = createContext(undefined)

export const SignalrProvider = ({ accessTokenFactory, actions, children }) => {
  const { dispatch, toast } = actions
  const notify = notifier(Actions.NOTIFICATION, dispatch)
  const announce = notifier(Actions.SYSTEM_ANNOUNCEMENT, dispatch)
  const session = useRef(undefined)
  useEffect(() => {
    (async () => {
      try {
        const token = await accessTokenFactory()
        if (token) {
          // Browser sleeping tab
          // https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio#bsleep
          let lockResolver
          if (navigator && navigator.locks && navigator.locks.request) {
            const promise = new Promise(resolve => {
              lockResolver = resolve
            })
            navigator.locks.request('sagittarius_browser_sleeping_tab', { mode: 'shared' }, () => {
              return promise
            })
          }

          const connection = buildSignalrConnection(token)
          connection.on('notification', message => notify(message))
          connection.on('connectionSlow', evt => {
            console.log('slow', evt)
            notify({ error: '网络不稳定，请检查您的网络情况...' })
          })
          connection.onreconnecting(() => notify({ error: '正在重新连接服务器...' }))
          connection.onclose(() => {
            if (lockResolver) {
              console.log('connection closed, release the web lock')
              lockResolver()
            }
            dispatch({ type: Actions.USER_DISCONNECTED })
          })
          connection.on('snatched', data => {
            dispatch({ type: Actions.TRANSACTION_SNATCHED, data })
          })
          connection.on('stopped', message => dispatch({ type: Actions.SNATCHER_STOPPED, message }))
          connection.on('kickOut', () => {
            notify({ error: '您的账号在异地登录，请不要重复登录，如果您对此有疑问，请联系客服。' })
            connection.stop()
          })
          connection.on('onlineMembers', data => dispatch({ type: Actions.SYSTEM_ONLINE_MEMBERS, data }))
          connection.on('announcement', message => announce(message))
          connection.on('reply', message => toast(message.message))
          dispatch({ type: Actions.USER_CONNECTING, connection })
          await connection.start()
          dispatch({ type: Actions.USER_CONNECTED, connection, token })
          // connection.stream('OnlineMembers').subscribe(onlineMembersSubscriber(dispatch))
          session.current = connection
          return () => {
            connection.stop()
          }
        }
      } catch (ex) {
        console.warn(ex)
        if (ex.error === 'login_required' || ex.error === 'consent_required') {
          notify({ error: '登录失败，请清除您浏览器的缓存并重新登录。' })
        } else {
          notify({ error: '连接服务失败，请确认您是否有足够的权限或者网络是否稳定。' })
        }
      }
    })()
  }, [accessTokenFactory, dispatch])
  return (
    <SignalrContext.Provider value={session.current}>
      {children}
    </SignalrContext.Provider>
  )
}

export const useSignalrConnection = () => useContext(SignalrContext)
