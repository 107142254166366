import styles from './Card.module.css'

export const Card = ({ children }) => {
  return (
    <div className={styles.card}>
      {children}
    </div>
  )
}

export const CardBody = ({ children }) => {
  return (
    <div className={styles.cardBody}>
      {children}
    </div>
  )
}
