import { createTheme } from '@fluentui/react'

const theme = createTheme({
  // defaultFontStyle: { fontFamily: 'Monaco, Menlo, Consolas', fontWeight: 'regular' },
  // fonts: {
  //   small: {
  //     fontSize: '14px'
  //   },
  //   medium: {
  //     fontSize: '16x'
  //   },
  //   large: {
  //     fontSize: '20px',
  //     fontWeight: 'semibold'
  //   },
  //   xLarge: {
  //     fontSize: '22px',
  //     fontWeight: 'semibold'
  //   }
  // }
})

export default theme
