import React from 'react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'

const Loading = () => (
  <div style={{ margin: '10rem' }}>
    <Spinner size={SpinnerSize.large} label='加载中，请稍后...' />
  </div>
)

export default Loading
