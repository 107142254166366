import { HubConnectionState } from '@microsoft/signalr'
import * as axios from 'axios'
import { DefaultButton, PrimaryButton, TextField, Stack, Pivot, PivotItem, Toggle, Separator } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator'
import * as Actions from '../infrastructure/Actions'
import { API_ENDPOINT } from '../infrastructure/CONST'
import { Card, CardBody } from './Card'
import { Label } from './Label'
import { useSignalrConnection } from '../infrastructure/SignalrConnection'
import { OnlineMemberList } from './OnlineMembers'
import { useUserInfo } from '../infrastructure/User'

const stackTokens = { childrenGap: 10 }
const startIcon = { iconName: 'Play' }
const stopIcon = { iconName: 'Stop' }
const extratCookie = { iconName: 'PublishContent' }

export const Control = ({ state, dispatch }) => {
  const {
    onlineMembers,
    isSnatching,
    cookie,
    endpoint,
    maxProcessTransactions,
    minAmount,
    maxAmount,
    snatchingInterval,
    parallelWorkers,
    username,
    password,
    otp,
    activeUser,
    isExtractingCookie,
    muted
  } = state
  const connection = useSignalrConnection()
  const userInfo = useUserInfo()
  const [isTest, { toggle: setIsTest }] = useBoolean(false)
  const [useVipChannel, { toggle: setUseVipChannel }] = useBoolean(false)

  const isConnected = connection !== undefined && connection.state === HubConnectionState.Connected
  const disabled = isSnatching || !isConnected || isExtractingCookie
  const onChange = settingsName => evt => dispatch({ type: Actions.SETTING_CHANGED, setting: settingsName, value: evt.target.value })
  const extractCookies = async () => {
    try {
      const { data } = await axios.post(`${API_ENDPOINT}/vcpay/login`, {
        user: username,
        password,
        otp
      })
      if (data.error) {
        dispatch({ type: Actions.EXTRACT_COOKIE_FAILED, error: data.error.value })
      } else {
        dispatch({ type: Actions.EXTRACT_COOKIE_SUCCEED, data: data.data })
      }
    } catch (error) {
      dispatch({ type: Actions.EXTRACT_COOKIE_FAILED, error: error.message })
    }
  }

  const admin = onlineMembers.members &&
     (
       <PivotItem headerText='在线会员' itemIcon='Group'>
         <OnlineMemberList onlineMembers={onlineMembers} activeUser={activeUser} />
       </PivotItem>
     )

  return (
    <Card>

      {!isSnatching &&
        <CardBody>
          <Pivot>
            <PivotItem headerText='自动提取' itemIcon='Signin'>
              <Stack horizontal tokens={stackTokens} verticalAlign='end'>
                <TextField onChange={onChange('username')} disabled={disabled} label='用户名' value={username} />
                <TextField
                  onChange={onChange('password')} disabled={disabled} label='密码' value={password} type='password'
                  canRevealPassword
                  revealPasswordAriaLabel='显示密码'
                />
                <TextField onChange={onChange('otp')} disabled={disabled} label='验证码' value={otp} />
                <DefaultButton
                  text='提取'
                  iconProps={extratCookie}
                  onClick={x => {
                    extractCookies()
                    dispatch({ type: Actions.EXTRACT_COOKIE })
                  }}
                  allowDisabledFocus
                  disabled={disabled}
                />
              </Stack>
              {cookie && <Label message={cookie} style={{ marginTop: '1rem' }} />}
              {endpoint && <Label message={endpoint} style={{ marginTop: '1rem' }} />}
            </PivotItem>
            <PivotItem headerText='手动输入' itemIcon='Edit'>
              <TextField onChange={onChange('cookie')} disabled={disabled} label='Cookie' value={cookie} multiline rows={2} />
              <TextField onChange={onChange('endpoint')} disabled={disabled} label='抓单地址' value={endpoint} />
            </PivotItem>
            {admin}
          </Pivot>
        </CardBody>}

      <CardBody>
        <Stack horizontal tokens={stackTokens} verticalAlign='end'>
          <TextField onChange={onChange('maxProcessTransactions')} disabled={disabled} label='最大待处理单数' value={maxProcessTransactions} />
          <TextField onChange={onChange('minAmount')} disabled={disabled} label='最小金额' value={minAmount} />
          <TextField onChange={onChange('maxAmount')} disabled={disabled} label='最大金额' value={maxAmount} />
          <TextField onChange={onChange('snatchingInterval')} disabled={disabled} label='抢单间隔(秒)' value={snatchingInterval} />
          <TextField onChange={onChange('parallelWorkers')} disabled={disabled} label='并发' value={parallelWorkers} />
          <PrimaryButton
            disabled={!isConnected}
            toggle onClick={async () => {
              if (!isSnatching) {
                await connection.send('Snatching',
                  cookie,
                  endpoint,
                  Number.parseInt(minAmount),
                  Number.parseInt(maxAmount),
                  Number.parseInt(snatchingInterval),
                  Number.parseInt(maxProcessTransactions),
                  Number.parseInt(parallelWorkers),
                  isTest,
                  useVipChannel
                )
              } else {
                await connection.send('Stop')
              }
              dispatch({ type: isSnatching ? Actions.STOP_SNATCHING : Actions.START_SNATCHING })
            }}
            iconProps={isSnatching ? stopIcon : startIcon}
            text={isSnatching ? '停止' : '开始'}
          />
          <Separator vertical />
          <Toggle
            label='仅测试' checked={isTest} disabled={disabled}
            onChange={(_, checked) => setIsTest(checked)}
          />
          <Toggle
            label='专线通道' checked={useVipChannel} disabled={disabled || !userInfo.isVIP}
            onChange={(_, checked) => setUseVipChannel(checked)}
          />
          <Toggle
            label='静音' checked={muted} disabled={disabled}
            onChange={(_, checked) => {
              dispatch({ type: Actions.SETTING_CHANGED, setting: 'muted', value: checked })
            }}
          />
        </Stack>
        {isSnatching && <ProgressIndicator description='正在抢单中...' />}
      </CardBody>
    </Card>
  )
}
