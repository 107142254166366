import * as React from 'react'
import { SharedColors } from '@fluentui/react'
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList'
import { formatAmount, formatDecimal } from '../infrastructure/Format'

const columns = [
  { key: 'id', name: 'ID', fieldName: 'id', minWidth: 50, maxWidth: 100, isResizable: true },
  { key: 'title', name: '标题', fieldName: 'title', minWidth: 100, maxWidth: 200, isResizable: true },
  { key: 'description', name: '备注', fieldName: 'description', minWidth: 200, maxWidth: 200, isResizable: true },
  { key: 'amount', name: '金额', fieldName: 'amount', minWidth: 100, maxWidth: 150, isResizable: true },
  { key: 'usdt', name: 'USDT', fieldName: 'usdt', minWidth: 50, maxWidth: 150, isResizable: true },
  { key: 'status', name: '状态', fieldName: 'status', minWidth: 60, maxWidth: 200, isResizable: true },
  { key: 'dateCreated', name: '时间', fieldName: 'dateCreated', minWidth: 60, maxWidth: 60, isResizable: false }
]

function getStatusName (status) {
  if (status === 'Available') {
    return '可抢单'
  } else if (status === 'InProgress') {
    return '等待付款'
  } else if (status === 'Verifying') {
    return '验证中'
  } else if (status === 'Done') {
    return '交易确认'
  } else if (status.kind === 'Failed') {
    return `抢单失败: ${status.value}`
  }
}

function getStatusColor (status) {
  if (status === 'Available') {
    return SharedColors.cyan10
  } else if (status === 'InProgress') {
    return SharedColors.red10
  } else if (status === 'Verifying') {
    return SharedColors.magenta20
  } else if (status === 'Done') {
    return SharedColors.greenCyan10
  }
}

const AmountCell = ({ amount }) => <div style={{ fontSize: '1.1em', fontWeight: '600' }}>{amount}</div>

function renderItemColumn (item, index, column) {
  const fieldContent = item[column.fieldName]

  switch (column.key) {
    case 'status':
    {
      const statusName = getStatusName(fieldContent)
      const color = getStatusColor(fieldContent)
      return <div style={{ color }}>{statusName}</div>
    }
    case 'amount':
      return <AmountCell amount={formatAmount(fieldContent)} />
    case 'usdt':
      return <AmountCell amount={formatDecimal(fieldContent)} />
    case 'dateCreated':
    {
      const dt = new Date(fieldContent)
      const ds = dt.toLocaleString('zh-CH', { timeZone: 'Asia/Shanghai' })
      return <span>{ds}</span>
    }
    default:
      return <span>{fieldContent}</span>
  }
}

export const TransactionList = ({ transactions }) => {
  return (
    <DetailsList
      items={transactions}
      columns={columns}
      setKey='id'
      onRenderItemColumn={renderItemColumn}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
      selectionPreservedOnEmptyClick
      ariaLabelForSelectionColumn='Toggle selection'
      ariaLabelForSelectAllCheckbox='Toggle selection for all items'
      checkButtonAriaLabel='select row'
    />
  )
}
