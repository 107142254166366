import useSound from 'use-sound'
import newMessageSfx from '../sounds/new_message.mp3'
import { usePageVisibility } from 'react-page-visibility'

export function SoundNotifier ({ playSound }) {
  const [play] = useSound(newMessageSfx, {
    interrupt: true
  })
  const isVisible = usePageVisibility()
  if (playSound && !isVisible) {
    play()
  }
  return <></>
}
