import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling'
import { Stack } from '@fluentui/react/lib/Stack'
import Header from './components/Header'
import Loading from './components/Loading'
import Login from './components/Login'

const Help = () => {
  const theme = getTheme()
  const classNames = mergeStyleSets({
    header: {
      color: theme.palette.neutralDark,
      padding: '15px',
      fontSize: '16px',
      fontWeight: '600'
    },
    content: {
      padding: '10px'
    }
  })
  const { isLoading, isAuthenticated, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Login error={error} />
  }

  if (!isAuthenticated) {
    return <Login error={{ message: '欢迎使用射手系统，请登录您的账号。' }} isLogin />
  }

  return (
    <>
      <Header />
      <Stack
        horizontalAlign='center'
        verticalAlign='center'
      >
        <div>
          <div role='heading' aria-level={1} className={classNames.header}>
            1）输入您的平台账号密码，以及验证码，点击提取按钮，获得用户cookie以及抢单地址。
          </div>
          <div className={classNames.content}>
            <img src='/help/step1.jpg' width={1300} />
          </div>
        </div>
        <div>
          <div role='heading' aria-level={1} className={classNames.header}>
            2）使用默认设置，点击开始按钮开始抢单。
          </div>
          <div className={classNames.content}>
            <img src='/help/step2.jpg' width={1300} />
          </div>
        </div>
      </Stack>
    </>
  )
}

export default Help
