import { ThemeProvider } from '@fluentui/react'
// import { AzureThemeLight } from '@fluentui/azure-themes'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AuthProvider from './components/AuthProvider'
import theme from './Theme'
import Home from './Home'
import Login from './Login'
import Help from './Help'
// const theme = AzureThemeLight

initializeIcons()

function App () {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/help' element={<Help />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
