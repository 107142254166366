import { useReducer } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Stack } from '@fluentui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { initialState, reducer } from './infrastructure/Store'
import { SignalrProvider } from './infrastructure/SignalrConnection'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Control } from './components/Control'
import { TransactionList } from './components/TransactionList'
import { Card } from './components/Card'
import { SoundNotifier } from './components/SoundNotifier'
import { Announcement } from './components/Announcement'
import Loading from './components/Loading'
import Login from './components/Login'
import { UserInfoProvider } from './infrastructure/User'

function Home () {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { isLoading, isAuthenticated, error, getAccessTokenSilently } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Login error={error} />
  }

  if (!isAuthenticated) {
    return <Login error={{ message: '欢迎使用射手系统，请登录您的账号。' }} isLogin />
  }

  const transactions = state.transactions
  const hasInProgress = transactions.filter(x => x.status === 'InProgress').length > 0
  return (
    <UserInfoProvider>
      <SignalrProvider accessTokenFactory={getAccessTokenSilently} actions={{ dispatch, toast }}>
        <Stack>
          <main>
            <ToastContainer autoClose={15000} />
            <Announcement announcement={state.announcement} />
            <SoundNotifier playSound={hasInProgress && !state.muted} />
            <Header
              token={state.token}
              userData={state.userData}
              notification={state.notification}
              onlineMembers={state.onlineMembers}
            />
            <Control state={state} dispatch={dispatch} />
            <Card>
              <TransactionList transactions={transactions} />
            </Card>
          </main>
          <Footer />
        </Stack>
      </SignalrProvider>
    </UserInfoProvider>
  )
}

export default Home
