import { useAuth0 } from '@auth0/auth0-react'
import {
  Stack, IconButton, Persona, PersonaSize, PersonaPresence, Spinner, SpinnerSize, TooltipHost
} from '@fluentui/react'
import { Notification } from './Notification'
import styles from './Header.module.css'
import { Separator } from '@fluentui/react/lib/Separator'
import { useNavigate } from 'react-router-dom'
// import Chat from './Chat'
import { OnlineMembers } from './OnlineMembers'
import { useUserInfo } from '../infrastructure/User'

function UserData ({ userData }) {
  return (
    <>
      <div className={styles.headerCell}>
        汇率: {userData.rate}
      </div>
      <Separator vertical />
      <div className={styles.headerCell}>
        USDT: {userData.remainingUsdt.toFixed(2)}
      </div>
      <Separator vertical />
      <div className={styles.totalAssets}>
        估值: {userData.remaining.toFixed(2)}
      </div>
      <Separator vertical />
      <div className={styles.totalAssets}>
        待支付: {userData.ongoingSell.toFixed(2)}
      </div>
      <Separator vertical />
    </>
  )
}

function UserStatus ({ user }) {
  return (
    <div className={styles.persona}>
      {user && (
        <Persona
          text={user.email}
          presence={PersonaPresence.online}
          size={PersonaSize.size24}
          imageAlt={user.email}
        />
      )}
      {!user && <Spinner size={SpinnerSize.small} />}
    </div>
  )
}

function Expiration ({ userInfo }) {
  const vipBadge = userInfo.isVIP && <label className={styles.vip}>专线</label>
  return (
    <>
      <div className={styles.headerCell}>
        <TooltipHost content={'到期时间 ' + userInfo.expiration.toLocaleString('zh-CH', { timeZone: 'Asia/Shanghai' })}>
          <div>{vipBadge} 剩余{userInfo.remainingDays}天</div>
        </TooltipHost>
      </div>
      <Separator vertical />
    </>
  )
}

export default function Header ({ token, userData, notification, onlineMembers }) {
  const { user, logout } = useAuth0()
  const userInfo = useUserInfo()
  const navigate = useNavigate()
  return (
    <div className={styles.header}>
      <Stack horizontal horizontalAlign='right'>

        <Notification notification={notification} />

        {notification && <Separator vertical />}

        {userData && <UserData userData={userData} />}

        {onlineMembers && <OnlineMembers onlineMembers={onlineMembers} />}

        <Expiration userInfo={userInfo} />

        <IconButton
          iconProps={{ iconName: 'Home' }}
          title='抢单'
          onClick={() => navigate('/')}
        />

        {/* <Separator vertical />

        <Chat token={token} user={user} /> */}

        <Separator vertical />

        <IconButton
          title='Skype'
          onClick={() => window.open('skype:live:.cid.8e1b35eed7035c3d?chat', '_self')}
          iconProps={{ iconName: 'SkypeLogo' }}
        />

        <Separator vertical />

        <IconButton
          iconProps={{ iconName: 'Help' }}
          title='帮助'
          onClick={() => navigate('/help')}
        />

        <Separator vertical />

        <IconButton
          iconProps={{ iconName: 'SignOut' }}
          title='登出' onClick={() => logout({
            returnTo: window.location.origin
          })}
        />

        <Separator vertical />

        <UserStatus user={user} />

      </Stack>
    </div>
  )
}
