import { TooltipHost, Text, GroupedList, Stack, DefaultButton, DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import styles from './OnlineMembers.module.css'
import { Separator } from '@fluentui/react/lib/Separator'
import { useSignalrConnection } from '../infrastructure/SignalrConnection'
import { HubConnectionState } from '@microsoft/signalr'

function ConnectionState ({ connection }) {
  const date = new Date(connection.dateConnected)
  return <li><b>{connection.connectionId}</b> <span>{date.toLocaleString()}</span></li>
}

const onRenderMember = connection => (nestingDepth, item, itemIndex, group) => {
  const userId = item[0]
  const userData = item[1]
  const isConnected = connection !== undefined && connection.state === HubConnectionState.Connected
  const disabled = !isConnected
  const stopUser = userId => connection.send('stopUser', userId)
  const kickOutUser = userId => connection.send('kickOut', userId)
  return (
    <div className={styles.onlineMembersItem}>
      <Stack>
        <Text variant='mediumPlus' nowrap block>{userData.email} - {userId}</Text>
        <Stack horizontal tokens={{ childrenGap: 10, padding: 5 }}>
          {userData.running && <span className={styles.running}>正在抢单</span>}
          {!userData.running && <span className={styles.idle}>闲置</span>}
          <DefaultButton disabled={disabled} text='强制停止' onClick={() => stopUser(userId)} />
          <DefaultButton disabled={disabled} text='断开连接' onClick={() => kickOutUser(userId)} />
        </Stack>
        <ul>
          {userData.connections.map((x, i) => <ConnectionState key={i} connection={x} />)}
        </ul>
      </Stack>
    </div>
  )
}

export function OnlineMembers ({ onlineMembers }) {
  const connection = useSignalrConnection()
  const tooltipProps = {
    onRenderContent: () => {
      if (onlineMembers.members) {
        const members = Object.entries(onlineMembers.members)
        return (
          <GroupedList
            items={members}
            onRenderCell={onRenderMember(connection)}
            compact
          />
        )
      }
    }
  }
  return (
    <>
      <div className={styles.onlineMembers}>
        <TooltipHost tooltipProps={tooltipProps} styles={{ root: { display: 'inline-block' } }}>
          <div>在线人数: {onlineMembers.count}</div>
        </TooltipHost>
      </div>
      <Separator vertical />
    </>
  )
}

const columns = [
  { key: 'id', name: 'ID', fieldName: 'id', minWidth: 120, maxWidth: 120, isResizable: true },
  { key: 'email', name: 'Email', fieldName: 'title', minWidth: 120, maxWidth: 120, isResizable: true },
  { key: 'status', name: '状态', fieldName: 'description', minWidth: 100, maxWidth: 100, isResizable: true },
  { key: 'options', name: '设置', fieldName: 'snatchingOptions', minWidth: 200, maxWidth: 400, isResizable: true },
  { key: 'orderSucceeded', name: '单数', fieldName: 'orderSucceeded', minWidth: 100, maxWidth: 100, isResizable: true },
  { key: 'connection', name: '链接', fieldName: 'connection', minWidth: 150, maxWidth: 300, isResizable: true },
  { key: 'actions', name: '', fieldName: 'usdt', minWidth: 50, maxWidth: 200, isResizable: true }
]

const renderItemColumn = connection => (item, index, column) => {
  const userId = item[0]
  const userData = item[1]
  const isConnected = connection !== undefined && connection.state === HubConnectionState.Connected
  const disabled = !isConnected
  const stopUser = userId => connection.send('stopUser', userId)
  const kickOutUser = userId => connection.send('kickOut', userId)
  switch (column.key) {
    case 'id':
    {
      return <span>{userId}</span>
    }
    case 'email':
    {
      return <span>{userData.email}</span>
    }
    case 'status':
    {
      return <div>{userData.running ? '正在抢单' : '闲置'}</div>
    }
    case 'options':
    {
      if (userData.snatchingOptions) {
        return (
          <ul style={{ padding: '0 0.2rem', margin: '0' }}>
            <li>间隔：{userData.snatchingOptions.interval}</li>
            <li>最小金额：{userData.snatchingOptions.minAmount}</li>
            <li>最大金额：{userData.snatchingOptions.maxAmount}</li>
            {/* <li>最大订单：{userData.snatchingOptions.maxProcessTransactions}</li> */}
            <li>并发：{userData.snatchingOptions.parallelWorkers}</li>
            <li>{userData.snatchingOptions?.endpoint}</li>
          </ul>
        )
      } else {
        return <div> - </div>
      }
    }
    case 'orderSucceeded':
    {
      return <span>{userData.orderSucceeded} 单</span>
    }
    case 'connection':
      return (
        <div>
          {userData.connections.map((x, i) => <ConnectionState key={i} connection={x} />)}
        </div>
      )
    default:
      return (
        <div>
          <DefaultButton disabled={disabled} text='强制停止' onClick={() => stopUser(userId)} />
          <DefaultButton disabled={disabled} text='断开连接' onClick={() => kickOutUser(userId)} />
        </div>
      )
  }
}

const onRenderRow = activeUser => (props, Row) => {
  const className = activeUser === props.item[0] ? styles.active : ''
  return <Row className={className} {...props} />
}

export function OnlineMemberList ({ onlineMembers, activeUser }) {
  const connection = useSignalrConnection()
  if (onlineMembers.members) {
    const members = Object.entries(onlineMembers.members)
    return (
      <DetailsList
        items={members}
        columns={columns}
        setKey='id'
        onRenderRow={onRenderRow(activeUser)}
        onRenderItemColumn={renderItemColumn(connection)}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        selectionPreservedOnEmptyClick
        ariaLabelForSelectionColumn='Toggle selection'
        ariaLabelForSelectAllCheckbox='Toggle selection for all items'
        checkButtonAriaLabel='select row'
      />
    )
  }
  return <></>
}
