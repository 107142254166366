import * as Actions from './Actions'

// '为了更好的为会员服务，我们对系统进行了架构更新，并调整了价格。单个购买800/月，同时购买两个700/月，三个或以上600/月，直接购买一年500每个月。'
// '射手抢单软件，诚招代理推广，互利共赢！详情加工作室客服 Skype 或微信（Sheshoukeji）咨询！'

const announcement = {
  message: '公告: 普通线800每月，专线1200每月，详情咨询客服微信 Sheshoukeji',
  date: '2022年9月19日'
}

export const initialState = {
  token: undefined,
  notification: undefined,
  announcement: announcement,
  reply: undefined,
  onlineMembers: { count: 0, members: {} },
  userData: undefined,
  transactions: [],
  isSnatching: false,
  cookie: undefined,
  endpoint: undefined,
  maxProcessTransactions: 5,
  minAmount: 1000,
  maxAmount: 15000,
  snatchingInterval: 5,
  parallelWorkers: 1,
  muted: false,
  username: undefined,
  password: undefined,
  otp: undefined,
  isExtractingCookie: false,
  activeUser: undefined
}

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.USER_CONNECTING:
    {
      return {
        ...state,
        notification: { message: '正在连接服务器..' }
      }
    }
    case Actions.USER_CONNECTED:
    {
      return {
        ...state,
        notification: { message: '连接服务器成功' },
        token: action.token
      }
    }
    case Actions.USER_DISCONNECTED:
    {
      return {
        ...state,
        notification: { error: '服务器连接断开，请刷新浏览器' }
      }
    }
    case Actions.NOTIFICATION:
      return {
        ...state,
        activeUser: action.data.userId,
        notification: action.data
      }
    case Actions.SYSTEM_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.data
      }
    case Actions.SYSTEM_REPLY:
      return {
        ...state,
        reply: action.data
      }
    case Actions.TRANSACTION_SNATCHED:
      return {
        ...state,
        activeUser: action.data.userId,
        userData: {
          ...action.data.user,
          userId: action.data.userId
        },
        transactions: action.data.transactions
      }
    case Actions.SNATCHER_STOPPED:
      return {
        ...state,
        isSnatching: false,
        activeUser: action.message.userId,
        notification: action.message
      }
    case Actions.START_SNATCHING:

      return {
        ...state,
        isSnatching: true
      }
    case Actions.STOP_SNATCHING:
      return {
        ...state,
        isSnatching: false
      }
    case Actions.SETTING_CHANGED:
      return {
        ...state,
        [action.setting]: action.value
      }
    case Actions.EXTRACT_COOKIE:
      return {
        ...state,
        isExtractingCookie: true
      }
    case Actions.EXTRACT_COOKIE_FAILED:
      return {
        ...state,
        notification: { error: `提取Cookie失败，${action.error}` },
        isExtractingCookie: false
      }
    case Actions.EXTRACT_COOKIE_SUCCEED:
      return {
        ...state,
        notification: { message: '提取Cookie成功' },
        cookie: action.data.session,
        endpoint: action.data.endpoint,
        isExtractingCookie: false
      }
    case Actions.SYSTEM_ONLINE_MEMBERS:
      return {
        ...state,
        onlineMembers: action.data
      }
    default:
      return state
  }
}
