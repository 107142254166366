export const NOTIFICATION = 'NOTIFICATION'
export const SNATCHER_STOPPED = 'SNATCHER_STOPPED'
export const TRANSACTION_SNATCHED = 'TRANSACTION_SNATCHED'
export const USER_CONNECTING = 'USER_CONNECTING'
export const USER_CONNECTED = 'USER_CONNECTED'
export const USER_DISCONNECTED = 'USER_DISCONNECTED'
export const START_SNATCHING = 'START_SNATCHING'
export const STOP_SNATCHING = 'STOP_SNATCHING'
export const SETTING_CHANGED = 'SETTING_CHANGED'
export const EXTRACT_COOKIE = 'EXTRACT_COOKIE'
export const EXTRACT_COOKIE_FAILED = 'EXTRACT_COOKIE_FAILED'
export const EXTRACT_COOKIE_SUCCEED = 'EXTRACT_COOKIE_SUCCEED'
export const SYSTEM_ONLINE_MEMBERS = 'SYSTEM_ONLINE_MEMBERS'
export const SYSTEM_ANNOUNCEMENT = 'SYSTEM_ANNOUNCEMENT'
export const SYSTEM_REPLY = 'SYSTEM_REPLY'
