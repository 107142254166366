import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState, createContext, useContext } from 'react'

function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
};

export function toUserInfo ({ idToken, accessToken }) {
  const permissions = new Set(parseJwt(accessToken).permissions)
  const expiration = new Date(idToken['https://sagittarius.com/expiration'])
  const level = idToken['https://sagittarius.com/level'] || 0
  const isVIP = level > 0 || permissions.has('vip:use_vip_channel')
  const days = Math.floor(Math.abs(new Date() - expiration) / 1000 / 60 / 60 / 24)
  return {
    expiration,
    level,
    isVIP,
    remainingDays: days,
    permissions
  }
}

export const UserContext = createContext(undefined)

export const UserInfoProvider = ({ children }) => {
  const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0()
  const [userInfo, setUserInfo] = useState({
    expiration: new Date(),
    level: 0,
    isVIP: false,
    remainingDays: 0,
    permissions: new Set()
  })
  useEffect(() => {
    (async () => {
      const idToken = await getIdTokenClaims()
      const accessToken = await getAccessTokenSilently()
      const userInfo = toUserInfo({ idToken, accessToken })
      setUserInfo({
        ...user,
        ...userInfo
      })
    })()
  }, [user, getIdTokenClaims, getAccessTokenSilently])

  return (
    <UserContext.Provider value={userInfo}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserInfo = () => useContext(UserContext)
