import {
  MessageBar,
  MessageBarType
} from '@fluentui/react'

export const Announcement = ({ announcement }) => {
  const { date, message } = announcement
  return (
    <MessageBar
      messageBarType={MessageBarType.warning}
      isMultiline={false}
      dismissButtonAriaLabel='Close'
      truncated
      overflowButtonAriaLabel='See more'
    >
      {date && date + ' - '}{message}
    </MessageBar>
  )
}
