import styles from '../styles/Home.module.css'

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <span className={styles.logo}>
        <img src='/logo192.png' alt='Logo' height={16} />
      </span>
    </footer>
  )
}
