import {
  MessageBar,
  MessageBarType
} from '@fluentui/react'

export const Notification = ({ notification }) => {
  if (!notification) return <></>

  const type = notification.error ? MessageBarType.error : MessageBarType.success
  const msg = notification.error || notification.message
  return (
    <MessageBar
      messageBarType={type}
      isMultiline={false}
      dismissButtonAriaLabel='Close'
      truncated
      overflowButtonAriaLabel='See more'
    >
      {notification.date || new Date().toLocaleString()} - {msg}
    </MessageBar>
  )
}
